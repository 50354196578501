.app{
  width: 100%;
  overflow: scroll;
  background-color: black;
  font-size: larger;
  height: 100vh;
}

.body{
  display: flex;
  flex-direction: column;
  /* margin: 25px 120px; */
  text-align: center;
  font-family: 'Abel', sans-serif;
  padding-bottom: 100px;
}

.menu{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menuItem{
  padding: 20px;
}

.border{
  border: red 1px solid;
}

.footer{
  position: absolute;
  left: 48%;
}

.video-container {
  overflow: hidden;
  position: relative;
}

.video-container video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/* Just styling the content of the div, the *magic* in the previous rules */
.video-container .caption {
  z-index: 1;
  position: relative;
  text-align: center;
  color: white;
  padding: 10px;
}



.menuBody{
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
  gap: 15px
}

.menuSection{
  padding: 10px;
  width: 450px;
  height: 62vh;
  border: 2px #f1eeea dotted;
  min-height: 580px;
}

/* .menuImage{
  height: 32%;
} */

.menuContent{
  height: 25%;
  font-family: monospace;
}

.itemName {
  padding-top: 20px;
  font-weight: bolder;
  font-family: monospace;
  font-size: xx-large;
  color: orange;
}

.menuAction{
  /* height: 16%; */
  /* text-align: center; */
  padding: 10px;
}

.modalFooter{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}

.prices{
  padding: 10px;
  border: dotted .5px orange;
  font-family: monospace;
  font-weight: bold;
}

.toggleButton{
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.MenuButtonSection{
  display: flex;
  flex-direction: row;
}

.dropdown{
  margin-left: 10px;
}

.Datepickercontainer{
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.Datepickerrow{
  flex-direction: row;
  justify-content: center;
  margin: 10px;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.DateMenuButton{
  width: 200px;
  height: 100px;
}

.DatapickerHeader{
  font-weight: bolder ;
  align-self: center;
  font-size: x-large;
  font-family: 'Abel', sans-serif;
}

.MenuContainer{
  width: 100%;
  min-height: 980px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

@media only screen and (max-width: 480px) {
  .menuBody{
    display: flex;
    flex-direction: column;
  }

  .menuSection{
    padding: 10px;
    width: 350px;
    height: 100vh;
    border: 2px #f1eeea dotted;
  }
  
  Button{
    margin: 10px;
    width: max-content;
  }

  .body{
    align-items: center;
    padding-bottom: 100px;
  }

  .menuContent{
    height: 25%;
    font-family: monospace;
  }
}